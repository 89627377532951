import React, { useEffect } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { Checkbox, TableCell, TableRow } from "@mui/material";
import { dollarFormatter, getPbcLocation } from "utils/helpers";
import { functions } from "utils/firebase";
import { set } from "lodash";

export const PBCCard = ({ item, onContacted, onCheckSent, onCheckCashed, summedNet }) => {
  const { org = {}, amount, date, fee = 0 } = item;

  const [paymentId, setPaymentId] = React.useState(item.cotPaymentId);

  useEffect(() => {
    if (item.cotPaymentId) {
      return setPaymentId(item.cotPaymentId);
    }
    functions.httpsCallable("getCotPaymentId")({ paymentId: item.paymentId }).then((res) => {
      setPaymentId(res.data);
      item.cotPaymentId = res.data;
    });
  }, [item.paymentId]);

  return (
    <TableRow>
      <TableCell>
        <PrimaryText fontSize={14} fontWeight={400}>
          {paymentId}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={14} fontWeight={400}>
          {org?.name}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={14} fontWeight={400}>
          {getPbcLocation(org)}
        </PrimaryText>
      </TableCell>

      <TableCell>
        <PrimaryText fontSize={14} fontWeight={400}>
          {org?.ein}
        </PrimaryText>
      </TableCell>

      <TableCell>
        <PrimaryText fontSize={14} fontWeight={400}>
          {amount}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={14} fontWeight={400}>
          {dollarFormatter.format(amount - fee)}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={14} fontWeight={400}>
          {dollarFormatter.format(summedNet)}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={14} fontWeight={400}>
          {date.toDate().toDateString()}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <Checkbox checked={item?.contacted} onClick={onContacted} />
      </TableCell>
      <TableCell>
        <Checkbox
          checked={item?.onboarded}
          disableTouchRipple={true}
          sx={{
            "&:hover": { backgroundColor: "transparent" },
            pointerEvents: "none",
          }}
        />
      </TableCell>
      <TableCell>
        <Checkbox checked={item?.checkSent} onClick={onCheckSent} />
      </TableCell>
      <TableCell>
        <Checkbox checked={item?.checkCashed} onClick={onCheckCashed} />
      </TableCell>
    </TableRow>
  );
};
